import { Disclosure } from "@headlessui/react";
import React from "react";

const Accordion = () => {
  return (
    <div className="section-padding  bg-white bg-[url('../images/all-img/section-bg-15.png')] bg-bottom  bg-cover bg-no-repeat">
      <div className="container">
        <div className="mini-title">Давтагддаг асуултууд</div>
        <div className="column-title ">
          Ерөнхий <span className="shape-bg">Асуултууд</span>
        </div>
        <ul className="list accrodains space-y-[30px] ">
          <Disclosure >
            {({ open }) => (
              <li className={open ? "open" : null} >
                <Disclosure.Button type="button" className="accrodain-button">
                  <span>ААНОАТ-ын тайланд 2024 оноос хэрэгжиж эхэлсэн чухал ямар заалтууд байна вэ? </span>
                  <span className="icon-pm"></span>
                </Disclosure.Button>

                <Disclosure.Panel className="content">
                  <ul>
                    <li>▶️ Орон нутагт шинээр барьсан нийтийн зориулалттай орон сууц борлуулсны орлогод ногдох татварыг 90%, 50%-р хөнгөлнө.</li>
                    <li> ▶️ Орон нутагт орон сууц түрээслүүлсний орлогод ногдох татварыг 90%, 50%-р хөнгөлнө</li>
                    <li>▶️ Гэр хорооллыг орон сууцжуулах үйл ажиллагааг  хэрэгжүүлж буй татвар төлөгчийн орлогод ногдох татварыг 90%-иар хөнгөлнө;</li>
                    <li>▶️ Гэр хорооллыг орон сууцжуулах ажилд зориулан газраа борлуулсан, шилжүүлснээс олсон орлогыг татвараас хөнгөлж чөлөөлнө</li>
                    <li>▶️ Дотоодын хөрөнгийн бирж дээр арилжаалсан хувьцаа, бонд зэрэг үнэт цаас борлуулсны орлогод ногдох татварыг 90%, 50%-р хөнгөлнө.</li>
                    <li>▶️ Цахилгаан эрчим хүчний 5 мв-с дээш, эсхүл дулааны эрчим хүчний 1.5 мв-с дээш хүчин чадалтай тоног төхөөрөмж үйлдвэрлэх үйл ажиллагааны орлогод ногдох татварыг 90% хөнгөлнө</li>
                  </ul>
                </Disclosure.Panel>
              </li>
            )}

          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <li className={open ? "open" : null} >
                <Disclosure.Button type="button" className="accrodain-button">
                  <span>Аялал жуулчлалын бизнес эрхлэгчид татварын ямар хөнгөлөлтүүд эдлэх вэ?</span>
                  <span className="icon-pm"></span>
                </Disclosure.Button>

                <Disclosure.Panel className="content">
                  <ul>
                    <li>▶️
                      Аялал жуулчлалын байгууллага отоглох цэг, авто замын дагуух түр амрах газар өөрийн ариун цэврийн байгууламжийг засварласан болон шинээр байгуулсан зардлыг татвар ногдох орлогоос хасах болсон.
                    </li>
                    <li>
                      ▶️ Аялал жуулчлалын гарааны компанийн эхний 3 жилийн бүтээгдэхүүн, үйлчилгээний борлуулалтын орлогод ногдох албан татварыг хөнгөлнө.
                    </li>
                    <li>
                      ▶️ Аяллын агент, Тур операторын гадаадын жуулчинд үзүүлсэн үйлчилгээг НӨАТ-с чөлөөлнө.</li>
                  </ul>
                </Disclosure.Panel>
              </li>
            )}

          </Disclosure>
          <Disclosure  >
            {({ open }) => (
              <li className={open ? "open" : null} >
                <Disclosure.Button type="button" className="accrodain-button">
                  <span>Хувь хүний татвартай холбоотой 2024 оноос хэрэгжиж эхэлсэн ямар заалтууд байна вэ?</span>
                  <span className="icon-pm"></span>
                </Disclosure.Button>

                <Disclosure.Panel className="content">
                <ul>
                    <li>▶️ Бараа ажил үйлчилгээний орлогын дүн НӨАТ төлөгч эсэхээс үл хамааран Хялбаршуулсан тайлангаар 1%-н орлогын татвар төлнө. Гол нь 2023 оны Хувь хүний орлогын албан татварын тайлангаа илгээсэн байх ёстой.
                    </li>
                    <li>
                      ▶️ УБ хотоос бусад газар орон сууц түрээслүүлсний орлогод ногдох татварыг 50%, 90%-р хөнгөлнө.
                    </li>
                    <li>
                      ▶️ Гэр хорооллыг орон сууцжуулах ажилд зориулан газраа зарсаны орлогод ногдох татварыг чөлөөлнө.</li>
                      <li>
                      
                      ▶️ Дотоодын хөрөнгийн бирж дээрх хувьцаа, бонд зэрэг үнэт цаасны борлуулсны орлогоос тухай бүр татвараа суутгуулж төлнө.</li>
                      <li>
                      ▶️ Малчин, мал бүхий этгээдийн борлуулсан таван хошуу мал болон малын гаралтай түүхий эдийг НӨАТ-аас чөлөөлнө</li>
                      <li>
                      ▶️ Тур оператор, Аяллын агентуудын гадаадын жуулчдад үзүүлсэн үйлчилгээ НӨАТ-аас чөлөөлнө</li>
                  </ul>
                </Disclosure.Panel>
              </li>
            )}

          </Disclosure>
        
        </ul>
      </div>
    </div>
  );
};

export default Accordion;
