// HomeOne.js
import React, { useState, useEffect } from "react";
import Banner from "../Banner";
import Blogs from "../Blogs";
import Feature from "../Feature";
import Footer from "../Footer";
import Header from "../Header";
import NewsLetter from "../NewsLetter";
import Main from "../Main";
import Counter from "./Counter";
// import BannerCourse from "./Popup";
// import AnnouncementBanner from "./Popup";

const HomeOne = () => {
  const [isPopupVisible, setPopupVisible] = useState(true);

  // useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  // }, []);

  // const closePopup = () => {
  //   setPopupVisible(false);
  // };

  return (
    <div>
      <Header />
      {/* <AnnouncementBanner /> */}
      <Main />
      <Banner />
      <Feature />
      <NewsLetter />
      <Counter />
      <Blogs />
      <Footer />
    </div>
  );
};

export default HomeOne;
