import React from "react";
import Header from "../Header";
import { anket, job } from "../../constant/images";

const Jobs = () => {
  return (
    <section className="welcome-area">
      <Header />
      <div className="container">
        <div className="row">
          <center>
            <div className="hero-text">
              {/* <h1 style={{ color: "black" }}>Нээлттэй ажлын байрууд</h1> */}
              <div
                class="bg-light bg-mcs p-4 p-md-5"
                className="jobs-container "
              >
                <div style={{ alignItems: "flex-start" }}>
                  <div class="row">
                    <div className="jobs-row">
                      <h5 className="jobs-header">Нээлттэй ажлын байр</h5>
                      <div className="jobs-body">
                        <div className="jobs-row-2 ">
                          <img
                            src={job}
                            alt="sss"
                            style={{
                              width: 300,
                              height: 240,
                              marginTop: -14,
                              borderRadius: 10,
                            }}
                          />
                        </div>
                        <ul
                          style={{
                            textAlign: "left",
                            marginLeft: 30,
                            width: "70%",
                          }}
                        >
                          <li></li>
                          <li>
                            Нягтлан бодох бүртгэл, санхүүгийн чиглэлээр их дээд
                            сургууль төгссөн
                          </li>
                          <li>
                            Санхүүгийн болон бусад хэрэглээний программууд дээр
                            ажилладаг
                          </li>
                          <li>
                            Үүрэгт ажлаа цаг хугацаанд нь үнэн зөв гүйцэтгэж
                            тайлагнах чадвартай
                          </li>
                          <li>Харилцааны болон багаар ажиллах ур чадвартай </li>
                          <li>
                            Дараах нөхцөлүүдийг давуу тал гэж үзнэ.Үүнд:
                            <ul style={{ marginLeft: 20 }}>
                              <li>- Татварын албанд ажиллаж байсан </li>
                              <li>
                                - Мэргэжлээрээ 3-с дээш жил ажиллаж байсан{" "}
                              </li>
                              <li>
                                - Шинэ төгсөгчийн голч оноо 3.5-с дээш бол{" "}
                              </li>
                              <li>- Бусад хэлний мэдлэгтэй бол </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "start",
                      }}
                    >
                      <ul>
                        <li>
                          Манай багийн нэг хэсэг болсоноор танд өөрийгөө
                          хөгжүүлэх дараах боломжууд бий болно.Үүнд:
                          <ul style={{ marginLeft: 20 }}>
                            <li>
                              - татварын эрх зүйн өндөр мэдлэг туршлагатай
                              зөвлөхүүдтэй хамтран ажиллаж суралцах
                            </li>
                            <li>
                              - аж ахуйн бүхий л салбарын бүртгэлийг ойлгож
                              хөтлөх ур чадвартай болох{" "}
                            </li>
                            <li>
                              - бизнесийн орчны санхүүгийн шинжилгээ хийх
                              чадвартай болох{" "}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="jobs-footer ">
                    <div>
                      Та нээлттэй ажлын байранд тавигдах шаардлагыг хангаж байна
                      гэж үзвэл онлайн анкетаа
                      <span style={{ color: "grey", fontSize: 20 }}>
                        taxtime8899@gmail.com
                      </span>
                      хаягт хандан илгээнэ үү.
                    </div>
                    <a
                      style={{ color: "blue" }}
                      href={anket}
                      class="btn btn-white mt-2"
                      download="anket.docx"
                    >
                      Анкетийн загвар татах
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </center>
        </div>
      </div>
    </section>
  );
};

export default Jobs;
