/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  bThumb1,
  calender,
  clockIcon,
  law,
  search,
  intTax,
} from "../../constant/images";
import Footer from "../Footer";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import callApi from "../../services/api";
import Accordion from "../HomeThreeComponents/Accordion";
import { useTranslation } from "react-i18next";

const BLogStandard = () => {
  const { t, i18n } = useTranslation();
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi(`/category`, {
          method: "GET",
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categoryData:", error);
      }
    };
    const fetchBlogData = async () => {
      try {
        const response = await callApi(`/blog`, {
          method: "GET",
        });
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching categoryData:", error);
      }
    };
    fetchData();
    fetchBlogData();
  });

  return (
    <>
      <Header />
      <div style={{ marginTop: 200, marginBottom: 100, padding: 40 }}>
        <div className="nav-tab-wrapper tabs  ">
          <div className="container">
            <div className="grid grid-cols-12 gap-[30px]">
              <div className="lg:col-span-8 col-span-12">
                <div className="grid  grid-cols-1 gap-[30px]">
                  {blogs.map((item, index) => (
                    <div className=" bg-white shadow-box12 rounded-[8px] transition duration-100 hover:shadow-box13">
                      <div
                        className="course-thumb h-[420px] rounded-t-[8px]  relative"
                        key={index}
                      >
                        <img
                          src={bThumb1}
                          alt=""
                          className=" w-full h-full object-cover rounded-t-[8px]"
                        />
                      </div>
                      <div className="course-content p-8">
                        <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white inline-block">
                          Тайлан
                        </span>
                        <h3 className=" mb-4 mt-4">
                          <div
                            className=" hover:text-primary transition duration-150"
                            onClick={() =>
                              navigate(`/blogs/${item.blog_id}`, {
                                state: { blog: item },
                              })
                            }
                          >
                            {item.title}
                          </div>
                        </h3>
                        <p>{item.content}</p>
                        <div className="flex lg:space-x-10 space-x-5 mt-6">
                          <div className=" flex items-center space-x-2">
                            <img src={calender} alt="" />
                            <span>{item.created_at}</span>
                          </div>
                          <div className=" flex items-center space-x-2">
                            <img src={clockIcon} alt="" />
                            <span>3 Min </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className=" bg-white shadow-box12 rounded-[8px] transition duration-100 hover:shadow-box13">
                    {/* <div
                    className="course-thumb h-[420px] rounded-t-[8px]  relative"
                    // key={index}
                  >
                    <img
                      src={bThumb1}
                      alt=""
                      className=" w-full h-full object-cover rounded-t-[8px]"
                    />
                  </div> */}
                    <div className="course-content p-8">
                      <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white inline-block">
                        {t("Мэдээ")}
                      </span>
                      <h3 className=" mb-4 mt-4">
                        <div
                          className=" hover:text-primary transition duration-150"
                          onClick={() => navigate(`/blogs/${4}`)}
                          style={{ fontSize: 24 }}
                        >
                          {t("Татварын зөвлөх үйлчилгээний талаар")}
                        </div>
                      </h3>
                      <p style={{ fontSize: 16 }}>
                        {t(
                          "Монгол Улсад Татварын зөвлөх үйлчилгээ үүсэж хөгжөөд 20 жил, Татварын  мэргэшсэн зөвлөх үйлчилгээний тухай хууль батлагдаад 10 гаруй жил болж байна. Гэсэн хэдий ч татварын мэргэшсэн зөвлөх үйлчилгээний талаар иргэд болон аж ахуйн нэгж байгууллагын удирдлагууд, олон нийт төдийлөн хангалттай хэмжээний ойлголтгүй, татварын мэргэшсэн зөвлөхүүдийг нягтлан  бодогчтой адилтган үзэх хандлагатай байсаар байна."
                        )}
                      </p>
                      <div className="flex   lg:space-x-10 space-x-5 mt-6">
                        <a
                          className=" flex items-center space-x-2"
                          href="blog-single.html"
                        >
                          <img src={calender} alt="" />
                          <span>1 Feb, 24</span>
                        </a>
                        <a
                          className=" flex items-center space-x-2"
                          href="single-blog"
                        >
                          <img src={clockIcon} alt="" />
                          <span>3 Min </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className=" bg-white shadow-box12 rounded-[8px] transition duration-100 hover:shadow-box13">
                    <div
                      className="course-thumb h-[420px] rounded-t-[8px]  relative"
                      // key={index}
                    >
                      <img
                        src={law}
                        alt=""
                        className=" w-full h-full object-cover rounded-t-[8px]"
                      />
                    </div>
                    <div className="course-content p-8">
                      <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white inline-block">
                        {t("Мэдээ")}
                      </span>
                      <h3 className=" mb-4 mt-4">
                        <div
                          className=" hover:text-primary transition duration-150"
                          onClick={() => navigate(`/blogs/${5}`)}
                          style={{ fontSize: 24 }}
                        >
                          {t("Гадаадын хөрөнгө оруулалтын татварын орчин")}
                        </div>
                      </h3>
                      <p style={{ fontSize: 16 }}>
                        {t(
                          "Монгол Улсын Хөрөнгө оруулалтын тухай хууль нь Монгол Улсын нутаг дэвсгэрт хөрөнгө оруулагчийн хууль ёсны эрх, ашиг сонирхлыг хамгаалах, хөрөнгө  оруулалтын эрх зүйн нийтлэг баталгааг тогтоох, хөрөнгө оруулалтыг дэмжих, татварын орчныг тогтворжуулах, хөрөнгө оруулалтын талаар төрийн байгууллагын эрх хэмжээ, хөрөнгө оруулагчийн эрх, үүргийг тодорхойлох болон хөрөнгө оруулалттай холбоотой бусад харилцааг зохицуулдаг."
                        )}
                      </p>
                      <div className="flex   lg:space-x-10 space-x-5 mt-6">
                        <a
                          className=" flex items-center space-x-2"
                          href="blog-single.html"
                        >
                          <img src={calender} alt="" />
                          <span>10 June, 24</span>
                        </a>
                        <a
                          className=" flex items-center space-x-2"
                          href="single-blog2"
                        >
                          <img src={clockIcon} alt="" />
                          <span>3 Min</span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className=" bg-white shadow-box12 rounded-[8px] transition duration-100 hover:shadow-box13">
                    <div
                      className="course-thumb h-[420px] rounded-t-[8px]  relative"
                      // key={index}
                    >
                      <img
                        src={intTax}
                        alt=""
                        className=" w-full h-full object-cover rounded-t-[8px]"
                      />
                    </div>
                    <div
                      className="course-content p-8"
                      style={{ fontSize: 24 }}
                    >
                      <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white inline-block">
                        {t("Мэдээ")}
                      </span>
                      <h3
                        className=" mb-4 mt-4"
                        style={{
                          fontSize: 24,
                        }}
                      >
                        <a
                          href="blogs/6"
                          className=" hover:text-primary transition duration-150"
                        >
                          {t("Олон улсын татварын талаар")}
                        </a>
                      </h3>
                      <p style={{ fontSize: 16 }}>
                        {t(
                          "Даяаршлын эрин үед худалдаа, хөрөнгө оруулалт, эдийн засгийн олон талт үйл ажиллагаа урьд өмнө төсөөлж байгаагүйгээр өргөжин тэлж, нэг улс орны төдийгүй бүс нутгийн хүрээнээс ч хальж тив дамжсан томоохон корпорациуд үүсэн бий болж өргөн цар хүрээгээр ажиллах болжээ."
                        )}
                      </p>
                      <div className="flex   lg:space-x-10 space-x-5 mt-6">
                        <a
                          className=" flex items-center space-x-2"
                          href="blog-single.html"
                        >
                          <img src={calender} alt="" />
                          <span>05 June, 24</span>
                        </a>
                        <a
                          className=" flex items-center space-x-2"
                          href="single-blog2"
                        >
                          <img src={clockIcon} alt="" />
                          <span>3 Min</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className=" bg-white shadow-box12 rounded-[8px] transition duration-100 hover:shadow-box13">
                    {/* <div
                      className="course-thumb h-[420px] rounded-t-[8px]  relative"
                    // key={index}
                    >
                      <img
                        src={law}
                        alt=""
                        className=" w-full h-full object-cover rounded-t-[8px]"
                      />
                    </div> */}
                    <div
                      className="course-content p-8"
                      style={{ fontSize: 24 }}
                    >
                      <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white inline-block">
                        {t("Мэдээ")}
                      </span>
                      <h3
                        className=" mb-4 mt-4"
                        style={{
                          fontSize: 24,
                        }}
                      >
                        <a
                          href="single-blog3"
                          className=" hover:text-primary transition duration-150"
                        >
                          {t(
                            "Аялал жуулчлалын салбарын “бэрхшээлийн тойрог” буюу Хуулийн ЗОХИЦУУЛАЛТЫН алдаа"
                          )}
                        </a>
                      </h3>
                      <p style={{ fontSize: 16 }}>
                        {t(
                          "Тайлангийн хугацаа дуусахаас бараг сарын өмнө бид аялал жуулчлалын салбарт үйл ажиллагаа эрхлэгчдэд сургалт хийсэн юм. Аялал жуулчлалын тухай хууль 2023 оны 5 дугаар сарын 4-ны өдөр шинэчлэгдэн батлагджээ. Тухайн салбарт гацаад байсан олон асуудлыг шийдвэрлэсэн, бас хөгжихөд нь түлхэц болох олон боломж олгосон хууль батлагдсанд энэ салбарт зүтгэж буй аж ахуйн нэгж, хувь хүн бүгд л баяртай байсан болов уу."
                        )}
                      </p>
                      <div className="flex   lg:space-x-10 space-x-5 mt-6">
                        <a
                          className=" flex items-center space-x-2"
                          href="blog-single.html"
                        >
                          <img src={calender} alt="" />
                          <span>05 June, 24</span>
                        </a>
                        <a
                          className=" flex items-center space-x-2"
                          href="single-blog2"
                        >
                          <img src={clockIcon} alt="" />
                          <span>3 Min</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pagination mt-14">
                  <ul className=" flex justify-center space-x-3">
                    <li className="inline-flex">
                      <a
                        href="#"
                        className="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold"
                      >
                        <iconify-icon
                          icon="heroicons:chevron-double-left-20-solid"
                          className="text-2xl"
                        ></iconify-icon>
                      </a>
                    </li>
                    <li className="inline-flex">
                      <a
                        href="#"
                        className="flex w-12 h-12 flex-col items-center justify-center bg-primary text-white rounded font-semibold"
                      >
                        1
                      </a>
                    </li>
                    <li className="inline-flex">
                      <a
                        href="#"
                        className="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold"
                      >
                        2
                      </a>
                    </li>
                    <li className="inline-flex">
                      <a
                        href="#"
                        className="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold"
                      >
                        3
                      </a>
                    </li>
                    <li className="inline-flex">
                      <a
                        href="#"
                        className="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold"
                      >
                        4
                      </a>
                    </li>
                    <li className="inline-flex">
                      <a
                        href="#"
                        className="flex w-12 h-12 flex-col items-center justify-center bg-[#ECECEC] rounded font-semibold"
                      >
                        <iconify-icon
                          icon="heroicons:chevron-double-right-20-solid"
                          className="text-2xl"
                        ></iconify-icon>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="lg:col-span-4 col-span-12">
                <div className="sidebarWrapper space-y-[30px]">
                  <div className="wdiget widget_search">
                    <div className="bg-[#F8F8F8] flex  rounded-md shadow-e1 items-center  py-[4px] pl-3  relative">
                      <div className="flex-1">
                        <input
                          type="text"
                          placeholder="Search keyword..."
                          className="border-none focus:ring-0 bg-transparent"
                        />
                      </div>
                      <div className="flex-none">
                        <button className="btn btn-primary">
                          <img src={search} alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="wdiget widget_catagory">
                    <h4 className="widget-title">{t("Ангиллууд")}</h4>
                    <ul className=" list-item space-y-4">
                      {categories.map((item, key) => (
                        <li className=" block" key={key}>
                          <a
                            href="#"
                            className=" flex justify-between bg-[#F8F8F8] py-[17px] px-5 rounded hover:bg-primary hover:text-white transition-all
                        duration-150"
                          >
                            <span>{item.name}</span>
                            <span className="text-2xl">
                              <iconify-icon icon="heroicons:chevron-right-20-solid"></iconify-icon>
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* <div className="wdiget widget-recent-post">
                    <h4 className=" widget-title">Холбоотой хичээлүүд</h4>
                    <ul className="list">
                      <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                        <div className="flex-none ">
                          <div className="h-20 w-20  rounded">
                            <img
                              src={rc1}
                              alt=""
                              className=" w-full h-full object-cover rounded"
                            />
                          </div>
                        </div>
                        <div className="flex-1 ">
                          <div className="mb-1 font-semibold text-black">
                            Үнэ шилжилтийн ерөнхий ойлголтууд
                          </div>
                          <a className=" text-secondary font-semibold" href="#">
                            Дэлгэрэнгүй
                          </a>
                        </div>
                      </li>
                      <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                        <div className="flex-none ">
                          <div className="h-20 w-20  rounded">
                            <img
                              src={rc2}
                              alt=""
                              className=" w-full h-full object-cover rounded"
                            />
                          </div>
                        </div>
                        <div className="flex-1 ">
                          <div className="mb-1 font-semibold text-black">
                            Үнэ шилжилтийн ерөнхий ойлголтууд
                          </div>
                          <a className=" text-secondary font-semibold" href="#">
                            Дэлгэрэнгүй
                          </a>
                        </div>
                      </li>
                      <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                        <div className="flex-none ">
                          <div className="h-20 w-20  rounded">
                            <img
                              src={rc3}
                              alt=""
                              className=" w-full h-full object-cover rounded"
                            />
                          </div>
                        </div>
                        <div className="flex-1 ">
                          <div className="mb-1 font-semibold text-black">
                            Үнэ шилжилтийн ерөнхий ойлголтууд
                          </div>
                          <a className=" text-secondary font-semibold" href="#">
                            Дэлгэрэнгүй
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div> */}
                  <div className="wdiget">
                    <h4 className="widget-title">{t("Түгээмэл хайлтууд")}</h4>
                    <ul className="flex flex-wrap">
                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          Business
                        </a>
                      </li>

                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          Education
                        </a>
                      </li>

                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          Design
                        </a>
                      </li>

                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          Students
                        </a>
                      </li>

                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          Teachers
                        </a>
                      </li>

                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          Online
                        </a>
                      </li>

                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          e-Learning
                        </a>
                      </li>

                      <li className="mr-2 mb-2">
                        <a
                          href="#"
                          className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
                        >
                          Book
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Accordion />
      <Footer />
    </>
  );
};

export default BLogStandard;
