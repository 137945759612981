import React from "react";
import { useTranslation } from "react-i18next";

const News = () => {
  const { t } = useTranslation();

  return (
    <div className="about-area">
      <div className="container" style={{ padding: 30 }}>
        <div className="news-container ">
          <div style={{ flex: 1, padding: 20 }}>
            <div className="news-header">
              {t("Яагаад биднийг сонгох ёстой вэ?")}
            </div>
            {/* <div className="news-header">
              Яагаад
              <span
                className="shape-bg"
                style={{ marginLeft: 10, marginRight: 10 }}
              >
                биднийг
              </span>{" "}
              сонгох ёстой вэ?
            </div> */}
            <div style={{ marginTop: 40, padding: 10 }}>
              <p className="news-p">
                {t("Татварын мэргэшсэн зөвлөх үйлчилгээ эрхлэх албан ёсны тусгай зөвшөөрөлтэй")}
              </p>
              <p className="news-p">
                {t("Татварын албанд 20-с дээш жил ажилласан ажлын туршлагатай")}
              </p>
              <p className="news-p">
                {t("Монгол Улсын төдийгүй дэлхийн зах зээлийн эдийн засгийн нөхцөл байдлын талаар өргөн мэдлэгтэй нэр хүндтэй зөвлөхтэй")}
              </p>
              <p className="news-p">
                {t("Санхүү, татварын хууль эрх зүйн туршлагатай өмгөөлөгч, хуульчдын багтай")}
              </p>
              <p className="news-p">
                {t("Бизнесийн байгууллагын нягтлан бодох бүртгэлээр мэргэшсэн багтай")}
              </p>
              <p className="news-p">
                {t("Бид Таны хэрэгцээ шаардлагад нийцсэн үнэ цэнэтэй зөвлөгөөг:")}
              </p>
              <ul style={{ marginLeft: 30 }}>
                <li>{t("мэргэжлийн түвшинд")}</li>
                <li>{t("хуулийн дагуу")} </li>
                <li>{t("цаг хугацаанд нь хүргэн үйлчлэх болно.")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
