import React from "react";
import { call, mail, map } from "../../constant/images";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import Header from "../Header";

const ContactUs = () => {
  return (
    <>
      <Header />
      <div style={{ padding: 200 }}>
        {/* <PageBanner title={"Бидэнтэй холбогдох"} pageName="Холбоо барих" /> */}
        <div class="nav-tab-wrapper tabs  section-padding">
          <div class="container">
            <div class=" grid grid-cols-12 gap-[30px]">
              <div class="xl:col-span-5 lg:col-span-6 col-span-12 ">
                {/* <div class="mini-title">Contact Us</div> */}
                <h4 class="column-title ">
                  <span class="shape-bg">Бидэнтэй</span> холбогдох
                </h4>
                <div>
                  {/* There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered. */}
                </div>
                <ul class=" list-item space-y-6 pt-8">
                  <li class="flex">
                    <div class="flex-none mr-6">
                      <div class="">
                        <img src={mail} alt="" class="" />
                      </div>
                    </div>
                    <div class="flex-1">
                      <h4 class=" lg:text-xl text-lg mb-1">Имэйл :</h4>
                      <div>taxtime8899@gmail.com</div>
                    </div>
                  </li>
                  <li class="flex">
                    <div class="flex-none mr-6">
                      <div class="">
                        <img src={call} alt="" class="" />
                      </div>
                    </div>
                    <div class="flex-1">
                      <h4 class=" lg:text-xl text-lg mb-1">Утас :</h4>
                      <div>99015440, 99097756, 99160604</div>
                    </div>
                  </li>
                  <li class="flex">
                    <div class="flex-none mr-6">
                      <div class="">
                        <img src={map} alt="" class="" />
                      </div>
                    </div>
                    <div class="flex-1">
                      <h4 class="lg:text-xl text-lg mb-1">Хаяг :</h4>
                      <div>
                        Сүхбаатар дүүрэг 6 дугаар хороо, Монреимпекс ХХК-ийн
                        байр, 208 тоот
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="xl:col-span-7 lg:col-span-6 col-span-12">
                <div class="bg-white shadow-box7 p-8 rounded-md">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ContactUs;
