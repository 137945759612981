import React from "react";
import mailIcon from "../assets/images/icon/mail.svg";
import question from "../assets/images/all-img/goals.png";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t, i18n } = useTranslation();
  return (
    <section
      className={`bg-[url('../images/banner/4.png')] bg-cover bg-no-repeat bg-center overflow-hidden`}
    >
      <div
        className="banner-container "
        // style={{ marginBottom: 40, marginTop: -150 }}
      >
        <div
          className="banner-container-sub"
          // style={{ display: "flex", flexDirection: "row" }}
        >
          <div className="banner-img-container">
            <img src={question} alt="mansOne" className="main-img" />
          </div>
          <div className="banner-container-2">
            <div className="container">
              <div className="grid grid-cols-16 ">
                <div className="xl:col-span-8 lg:col-span-8 col-span-8">
                  <h4 className="banner-container-2-h1 ">
                    {t("ЭРХЭМ ЗОРИЛГО")}
                    <span className="shape-bg"></span>
                  </h4>
                  <div className="banner-container-2-text ">
                    {t(
                      "Бизнес эрхлэгчдийн татвар, санхүүгийн элдэв асуудлаа даатгах найдвартай түнш нь байх"
                    )}
                  </div>
                  {/* <div className=" mb-2">
                    Төрийн сан хөмрөгийг татварын орлогоор бүрдүүлэхэд хувь
                    нэмрээ оруулах
                  </div> */}
                </div>
              </div>
            </div>

            <div className="container">
              <div className="grid grid-cols-16 ">
                {/* <div style={{ marginLeft: 20, width: 100, height: 100 }}>
                  <img src={about3} alt="about5" />
                </div> */}
                <div className="xl:col-span-8 lg:col-span-8 col-span-8">
                  <h4 className="banner-container-2-h1 ">
                    {t("ҮНЭТ ЗҮЙЛ")}
                    <span className="shape-bg"></span>
                  </h4>
                  <div className="banner-container-2-text ">
                    {t("Бизнес, хамтын ажиллагааны өндөр ёс зүй")}
                  </div>
                  <div className="banner-container-2-text ">
                    {t("Ажлын арвин их туршлага, нэр хүнд")}
                  </div>
                  <div className="banner-container-2-text ">
                    {t("Татвар, санхүүгээр нарийн мэргэшсэн баг хамт олон")}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="grid grid-cols-16 " style={{ marginLeft: 0 }}>
                <div className="xl:col-span-8 lg:col-span-8 col-span-8">
                  <h4 className="banner-container-2-h1 ">
                    {t("АЛСЫН ХАРАА")}
                    <span
                      className="shape-bg"
                      style={{ marginTop: 40, fontFamily: "Roboto" }}
                    ></span>
                  </h4>
                  <div className="banner-container-2-text ">
                    {t(
                      "Татвар, санхүү, бизнесийн зөвлөх үйлчилгээгээр тэргүүлэгч Монголдоо төдийгүй дэлхийд хүлээн зөвшөөрөгдөх шилдэг ТАТВАРЫН МЭРГЭШСЭН ЗӨВЛӨХ компани болох"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white md:flex  rounded-md shadow-e1 items-center email-container">
          <div className="flex-1 flex items-center lg:mb-0 mb-2">
            <div className="flex-none">
              <span className=" ">
                <img src={mailIcon} alt="mainIcon" />
              </span>
            </div>
            <div className="flex-1">
              <input
                type="text"
                placeholder="Имэйл хаягаа оруулах"
                className="border-none focus:ring-0"
              />
            </div>
          </div>
          <div className="flex-none">
            <button
              className="btn btn-primary w-full lg:w-auto"
              style={{
                backgroundColor: "white",
                color: "#29c8e0",
                border: "1px solid #29c8e0",
              }}
            >
              {t("одоо эхлэх")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
