import React from "react";
import intro from "../assets/images/all-img/intro.png";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="intro-container">
        <h2>{t("Танилцуулга")}</h2>
        <div className="intro-sub">
          <p className="intro-sub-text">
            Бид Монголын Татварын албанд олон жил ажилласан ажлын туршлага
            дээрээ үндэслэн бизнес эрхлэгч иргэд, аж ахуй нэгжүүдэд санхүү,
            татварын
          </p>
          мэргэжлийн дэмжлэг туслалцаа үзүүлэх, байгууллагын үйл ажиллагаанд
          татварын талаас үр ашигтай менежментийг хэрэгжүүлэх, татварын хэмнэлт
          гаргах, татвар ногдуулалт төлөлтийн стратегиудыг тодорхойлох,
          загварчлах, татварын эрсдлээс урьдчилан сэргийлэх чиглэлээр экспертийн
          дэмжлэг туслалцаа үзүүлэх зорилгоор энэхүү компаниа үүсгэн байгуулан
          ажиллаж эхэллээ.
          <div className="intro-sub-container">
            <img src={intro} alt="dd"></img>
            Сүүлийн жилүүдэд Монгол Улсын Татварын багц хуулийн шинэчлэл болон
            олон улсын татварын бодлогын чиг хандлага нь татварыг хуулийн дагуу,
            үнэн зөв, шудрага ногдуулахад чиглэж олон төрлийн журам, заавар,
            аргачлал гаргасан. Тэдгээр заавал биелүүлэх ёстой хуулийн заалт,
            дүрэм, журмуудтай танилцаагүй, мэдээгүйгээс татвар төлөгчид гэнэтийн
            их хэмжээний татварын төлбөр торгуульд орж бизнесийн хэвийн үйл
            ажиллагаа нь алдагдах нь элбэг тохиолдох болсон. Мөн санхүүгийн
            тайлан балансыг татварын бүртгэл, тайлантай уялдуулах нь татварын
            элдэв эрсдэлд орохгүй байх нөхцлийг бүрдүүлэх гол хүчин зүйл болдог
            бөгөөд татварын хяналт шалгалтын явцад санхүүгийн тайланг уншиж,
            түүнтэй танилцах нь шалгалтыг цаашид хэрхэн үргэлжлүүлэхийг шийддэг
            гол үзүүлэлт, мэдээлэл нь болдог.
          </div>
          <p className="intro-sub-2"></p>
          <p style={{ textAlign: "justify", marginTop: 10 }}>
            Санхүүгийн тайлан балансаа үнэн зөв тайлагнах нь бизнесийн цаашдын
            өсөлт хөгжилт болон шийдвэр гаргалтанд чухал ач холбогдолтой байдаг.
            Таны бизнес
          </p>
          <p>
            {" "}
            хэр үр дүнтэй байгааг санхүүгийн үзүүлэлтүүд, түүн дээр үндэслэн
            гаргасан үр ашгийн тооцооллууд илтгэн харуулдаг.
          </p>
          <p className="intro-sub-2">
            Иймд бид тухайн бизнесийг ажил гүйлгээ хийхээс эхлээд санхүүгийн
            анхан шатны баримт материал бүрдүүлэх, удирдлагад зориулсан
            санхүүгийн
          </p>
          шинжилгээ хийх, сар, улирал, жилийн тайлангуудыг хуулийн дагуу үнэн
          зөв бэлтгэн холбогдох байгууллагуудад илгээх зэргээр санхүү, татвартай
          холбоотой бүхий л асуудалд туслан хамтран ажиллах боломжтой боллоо.
          <p className="intro-sub-2">
            Мөн гадаад, дотоодын хөрөнгө оруулагчдад зориулсан бизнесийн
            судалгаа шинжилгээ хийдэг, олон жилийн дадлага туршлагатай солонгос,
            хятад хэлтэй
          </p>
          мэргэжилтэнгүүд бидэнтэй нэгдэн ажиллаж байна.
          <p className="intro-sub-2">
            Бид Татварын мэргэшсэн зөвлөх үйлчилгээний тухай хуулийн дагуу
            "Татварын мэргэшсэн зөвлөх" тусгай зөвшөөрөл олгох шаардлагуудыг
            хангасан тул
          </p>
          <p>
            {" "}
            татварын итгэмжлэгдсэн хуулийн этгээд болон албан ёсоор үйл
            ажиллагаагаа эрхлэн явуулж байна.
          </p>
          <p className="intro-sub-2">
            Цаашид татвар төлөгч иргэд, аж ахуйн нэгжүүдэд татвар, санхүүтэй
            холбоотой асуудалдаа санаагаа чилээхгүйгээр үйл ажиллагаагаа явуулах
          </p>
          боломжийг олгон хамтран ажиллахад манай баг хамт олон бэлэн байна.
        </div>
      </div>
      <div className="intro-sub-3">
        <div className="intro-sub-3-text">
          <p>Хүндэтгэсэн</p>
          <p>ТАКС ТАЙМ ТМЗ ХХК</p>
        </div>
      </div>
    </>
  );
};

export default Intro;
