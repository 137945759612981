import React from "react";
import Header from "./Header";
import { star } from "../constant/images";
import { useTranslation } from "react-i18next";

const Feature = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Header></Header>
      <div className=" section-padding">
        <div className="feature-area bg-cover bg-no-repeat bg-center section-padding">
          <div className="container" style={{fontFamily:"Roboto"}}>
            <div className="text-center">
              <div className="mini-title" style={{ fontSize: 30 }}>
                
                {t("Онцлог, давуу талууд")}
              </div>
              <div className="column-title ">

              {t("Бид ямар үйлчилгээ үзүүлдэг вэ?")}
                {/* Бид ямар <span className="shape-bg">үйлчилгээ</span> үзүүлдэг
                вэ? */}
              </div>
            </div>
            <h3 style={{ marginLeft: 20,color:"#29c8e0", fontFamily:"Roboto" }}>{t("Татварын зөвлөх үйлчилгээ")} </h3>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10" style={{fontFamily:"Roboto"}}>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  {t("Татварын тайлан мэдээ бэлтгэх үйлчилгээ")}
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Аж ахуйн нэгжийн орлогын албан татварын тайлан")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Үнэ шилжилтийн тайлан")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Нэмэгдсэн өртгийн албан татварын тайлан")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Оршин суугч бус татвар төлөгчийн тайлан")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("АМНАТ тайлан")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Хувь хүний орлогын албан татварын тайлан")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  {t("Татварын багц хуулийн талаар зөвлөгөө өгөх үйлчилгээ")}
                </h4>
                <div className="transition duration-150 group-hover:text-white"  style={{fontFamily:"Roboto"}}>
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Тухайлсан татварын хууль хэрэглээний талаар зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Алдагдал баталгаажуулах болон шилжүүлэхэд зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Татварын хөнгөлөлт чөлөөлөлт эдлэхэд зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Давхар татварын гэрээний дагуу хөнгөлөлт эдлэхэд зөвлөх")}
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  {t("Татварын эрсдлийг тодорхойлох үйлчилгээ")}
                </h4>
                <div className="transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Татварын тайлангуудыг үнэн зөв тайлагнаж байгаа эсэхийг тодорхойлох")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                     {t("Татварын хяналт шалгалтаар илэрч болзошгүй зөрчлүүдийг тодорхойлох")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Татварын эрсдлийн үнэлгээг зохистой түвшинд байлгахад зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Алдаатай тайлагнасан татварын тайланг залруулах")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
                  {t("Татварын хяналт шалгалт, маргаантай асуудалд зөвлөх үйлчилгээ")}
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Татварын хяналт шалгалтанд татвар төлөгчийг төлөөлөн оролцох")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Маргаантай татварын нөхөн ногдуулалтын акт болон бусад шийдвэрүүдэд тайлбар гаргах")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Маргаан таслах зөвлөл болон шүүх хяналтын шатны хуралд оролцох")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  {t("Татварын төлөвлөлт, тооцоолол хийх үйлчилгээ")}
                </h4>
                <div className="transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Бизнесийн онцлог байдалд тохирсон төлөвлөлт")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Олон улсын татвар, давхар татварын гэрээний дагуу төлөвлөлт")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Тухайн ажил гүйлгээний зураглалын дагуу төлөвлөлт")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Хувь хүн болон бизнес эрхлэгчийн төлөх татварын тооцоолол")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Ашигт малтмалын хайгуулын болон ашиглалтын тусгай зөвшөөрөл шилжүүлэхэд төлөх татварын тооцоолол")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
                  {t("Татварын хуулийн талаар сургалт явуулах")}
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Бизнесийн салбар бүрээр захиалгат сургалт")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Захирлуудад зориулсан VIP сургалт")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Татварын төрөл бүрээр онлайн болон танхимын сургалт")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Бусад онлайн болон танхимын сургалт")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <h3 style={{ marginTop: 30, marginLeft: 20,color:"#29c8e0" }}>
              {t("Санхүүгийн зөвлөх үйлчилгээ")}
            </h3>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  {t("Санхүүгийн тайлан баланс гаргах")}
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Нягтлан бодох бүртгэлийн аутсорсинг үйлчилгээ /Санхүүгийн тайлан гаргах/")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Ерөнхий нягтлан бодогчийн үйлчилгээ /Санхүүгийн тайлан гаргах/")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Цалин тооцоолох, холбогдох тайлангуудыг гаргах")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Нийгмийн даатгалын тайлан гаргах")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Санхүүгийн анхан шатны баримт цэгцлэх")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  {t("Санхүүгийн шинжилгээ")}
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Бизнесийн үр ашгийн тооцоолол")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Хөдлөх болон үл хөдлөх хөрөнгийн бүртгэл, элэгдлийн тооцоолол")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Санхүүжилтийн стратеги гаргах")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Мөнгөн урсгалын төсөв зохиох")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h3 style={{ marginTop: 30, marginLeft: 20 ,color:"#29c8e0"}}>
              {t("Бизнесийн зөвлөх үйлчилгээ")}
            </h3>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  {t("Гадаадын хөрөнгө оруулагчдад")}
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Гадаадын хөрөнгө оруулалттай компани, төлөөний газар үүсгэн байгуулах")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Цаашид үйл ажиллагаагаа хэвийн жигд явуулахад зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Гадаадын хөрөнгө оруулагчдад оршин суух зөвшөөрөл шинээр авах, сунгуулах болон холбогдох бусад бичиг баримтыг бүрдүүлэх, зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Хөрөнгө оруулалт хийх татварын орчны судалгаа шинжилгээ хийх, зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Сонирхож буй бизнесийн чиглэлээр нь судалгаа шинжилгээ хийх, зөвлөх")}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  {t("Дотоодын хөрөнгө оруулагчдад")}
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Бизнес төсөл боловсруулах")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Хөрөнгө оруулалтын үр ашгийн тооцоолол хийх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Хөрөнгө оруулалт хийх татварын орчны судалгаа шинжилгээ хийх, зөвлөх")}
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      {t("Сонирхож буй бизнесийн чиглэлээр нь судалгаа шинжилгээ хийх, зөвлөх")}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
