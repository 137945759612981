import React from "react";
import Header from "./Header";
import { star } from "../constant/images";
const Feature = () => {
  return (
    <>
      <Header></Header>
      <div className=" section-padding">
        <div className="feature-area bg-cover bg-no-repeat bg-center section-padding">
          <div className="container" style={{fontFamily:"Roboto"}}>
            <div className="text-center">
              <div className="mini-title" style={{ fontSize: 30 }}>
                Онцлог, давуу талууд
              </div>
              <div className="column-title ">
                Бид ямар <span className="shape-bg">үйлчилгээ</span> үзүүлдэг
                вэ?
              </div>
            </div>
            <h3 style={{ marginLeft: 20,color:"#29c8e0", fontFamily:"Roboto" }}>Татварын зөвлөх үйлчилгээ </h3>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10" style={{fontFamily:"Roboto"}}>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  Татварын тайлан мэдээ бэлтгэх үйлчилгээ
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Аж ахуйн нэгжийн орлогын албан татварын тайлан
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Үнэ шилжилтийн тайлан
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Нэмэгдсэн өртгийн албан татварын тайлан
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Оршин суугч бус татвар төлөгчийн тайлан
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      АМНАТ тайлан
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Хувь хүний орлогын албан татварын тайлан
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  Татварын багц хуулийн талаар зөвлөгөө өгөх үйлчилгээ
                </h4>
                <div className="transition duration-150 group-hover:text-white"  style={{fontFamily:"Roboto"}}>
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Тухайлсан татварын хууль хэрэглээний талаар зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Алдагдал баталгаажуулах болон шилжүүлэхэд зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Татварын хөнгөлөлт чөлөөлөлт эдлэхэд зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Давхар татварын гэрээний дагуу хөнгөлөлт эдлэхэд зөвлөх
                    </li>
                  </ul>
                </div>
              </div>

              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  Татварын эрсдлийг тодорхойлох үйлчилгээ
                </h4>
                <div className="transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Татварын тайлангуудыг үнэн зөв тайлагнаж байгаа эсэхийг
                      тодорхойлох
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Татварын хяналт шалгалтаар илэрч болзошгүй зөрчлүүдийг
                      тодорхойлох
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Татварын эрсдлийн үнэлгээг зохистой түвшинд байлгахад
                      зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Алдаатай тайлагнасан татварын тайланг залруулах
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
                  Татварын хяналт шалгалт, маргаантай асуудалд зөвлөх үйлчилгээ
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Татварын хяналт шалгалтанд татвар төлөгчийг төлөөлөн
                      оролцох
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Маргаантай татварын нөхөн ногдуулалтын акт болон бусад
                      шийдвэрүүдэд тайлбар гаргах
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Маргаан таслах зөвлөл болон шүүх хяналтын шатны хуралд
                      оролцох
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  Татварын төлөвлөлт, тооцоолол хийх үйлчилгээ
                </h4>
                <div className="transition duration-150 group-hover:text-white" style={{fontFamily:"Roboto"}}>
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Бизнесийн онцлог байдалд тохирсон төлөвлөлт
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Олон улсын татвар, давхар татварын гэрээний дагуу
                      төлөвлөлт
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Тухайн ажил гүйлгээний зураглалын дагуу төлөвлөлт
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Хувь хүн болон бизнес эрхлэгчийн төлөх татварын тооцоолол
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Ашигт малтмалын хайгуулын болон ашиглалтын тусгай
                      зөвшөөрөл шилжүүлэхэд төлөх татварын тооцоолол
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
                  Татварын хуулийн талаар сургалт явуулах
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Бизнесийн салбар бүрээр захиалгат сургалт
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Захирлуудад зориулсан VIP сургалт
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Татварын төрөл бүрээр онлайн болон танхимын сургалт
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Бусад онлайн болон танхимын сургалт
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <h3 style={{ marginTop: 30, marginLeft: 20,color:"#29c8e0" }}>
              Санхүүгийн зөвлөх үйлчилгээ
            </h3>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  Санхүүгийн тайлан баланс гаргах
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Нягтлан бодох бүртгэлийн аутсорсинг үйлчилгээ /Санхүүгийн
                      тайлан гаргах/
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Ерөнхий нягтлан бодогчийн үйлчилгээ /Санхүүгийн тайлан
                      гаргах/
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Цалин тооцоолох, холбогдох тайлангуудыг гаргах
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Нийгмийн даатгалын тайлан гаргах
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Санхүүгийн анхан шатны баримт цэгцлэх
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  Санхүүгийн шинжилгээ
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Бизнесийн үр ашгийн тооцоолол
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Хөдлөх болон үл хөдлөх хөрөнгийн бүртгэл, элэгдлийн
                      тооцоолол
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Санхүүжилтийн стратеги гаргах
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Мөнгөн урсгалын төсөв зохиох
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <h3 style={{ marginTop: 30, marginLeft: 20 ,color:"#29c8e0"}}>
              Бизнесийн зөвлөх үйлчилгээ
            </h3>
            <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10">
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  Гадаадын хөрөнгө оруулагчдад
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Гадаадын хөрөнгө оруулалттай компани, төлөөний газар
                      үүсгэн байгуулах
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Цаашид үйл ажиллагаагаа хэвийн жигд явуулахад зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Гадаадын хөрөнгө оруулагчдад оршин суух зөвшөөрөл шинээр
                      авах, сунгуулах болон холбогдох бусад бичиг баримтыг
                      бүрдүүлэх, зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Хөрөнгө оруулалт хийх татварын орчны судалгаа шинжилгээ
                      хийх, зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Сонирхож буй бизнесийн чиглэлээр нь судалгаа шинжилгээ
                      хийх, зөвлөх
                    </li>
                  </ul>
                </div>
              </div>
              <div className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
                <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-3 transition duration-150 group-hover:text-white">
                  Дотоодын хөрөнгө оруулагчдад
                </h4>
                <div className="transition duration-150 group-hover:text-white">
                  <ul>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Бизнес төсөл боловсруулах
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Хөрөнгө оруулалтын үр ашгийн тооцоолол хийх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Хөрөнгө оруулалт хийх татварын орчны судалгаа шинжилгээ
                      хийх, зөвлөх
                    </li>
                    <li style={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={star}
                        class="custom-icon"
                        alt="icon"
                        style={{ marginRight: 10 }}
                      ></img>
                      Сонирхож буй бизнесийн чиглэлээр нь судалгаа шинжилгээ
                      хийх, зөвлөх
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feature;
