import React from "react";
import { call, mail, map, qpay } from "../../constant/images";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import { Button, Card, Col, Flex, Form, Input, Row, Space, Tabs } from "antd";
import {
  CreditCardOutlined,
  MailOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

const Payment = () => {
  return (
    <>
      <Header />
      <PageBanner pageName="Төлбөр төлөлт" />
      <div className="nav-tab-wrapper tabs  section-padding">
        <div className="container">
          <Row className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[30px]">
            <Col>
              <h4 className="column-title ">
                <span>Захиалгын мэдээлэл</span>
              </h4>
              <Card
                direction="vertical"
                style={{ width: "100%", marginBottom: 20, marginTop: 20 }}
              >
                <Space direction="vertical" style={{ width: "100%" }}>
                  <span style={{ fontSize: "1.125rem" }} className="mini-title">
                    Төрөл:
                  </span>
                  <p style={{ fontSize: "1.125rem" }}>ddd</p>
                </Space>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <span style={{ fontSize: "1.125rem" }} className="mini-title">
                    Сонгосон өдөр:
                  </span>
                  <p style={{ fontSize: "1.125rem" }}>ddd</p>
                </Space>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <span style={{ fontSize: "1.125rem" }} className="mini-title">
                    Сонгосон цагууд:
                  </span>
                  <p style={{ fontSize: "1.125rem" }}> ddd</p>
                </Space>
                <span className="mini-title" style={{ fontSize: "1.125rem" }}>
                  Нийт төлбөр:
                </span>
                <p style={{ fontSize: "1.125rem" }}>100000₮</p>
              </Card>
            </Col>
            <Col>
              {/* <h4 className="column-title "> */}
              <span>Төлбөр</span>
              {/* </h4> */}
              <Tabs>
                <Tabs.Items tab="Карт" key="card">
                  <Card>
                    <Form
                      name="wrap"
                      labelCol={{
                        flex: "210px",
                      }}
                      labelAlign="left"
                      labelWrap
                      wrapperCol={{
                        flex: 1,
                      }}
                      colon={false}
                      style={{
                        maxWidth: 600,
                      }}
                    >
                      <Form.Item
                        label="email"
                        name="email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          prefix={<MailOutlined />}
                          placeholder="Имэйл хаягаа оруулна уу"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Картын дугаар"
                        name="cardNumber"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          prefix={<CreditCardOutlined />}
                          placeholder="Картын дугаар оруулна уу"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Дуусах хугацаа"
                        name="expireDate"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          prefix={<CalendarOutlined />}
                          placeholder="Дуусах хугацаа MM/YYYY"
                        />
                      </Form.Item>
                      <Form.Item
                        label="CVV"
                        name="cvv"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          prefix={<CalendarOutlined />}
                          placeholder="CVV"
                        />
                      </Form.Item>
                      <Flex
                        vertical
                        gap="small"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Button type="primary" danger>
                          Төлбөр төлөх
                        </Button>
                      </Flex>
                    </Form>
                  </Card>
                </Tabs.Items>
                <Tabs.Item tab="QPAY" key="qpay" style={{ width: 300 }}>
                  <Row justify="space-around" style={{ marginTop: 20 }}>
                    <Col span={10} push={6}>
                      <Card>
                        <img alt="ll" src={qpay}></img> <span>QPAY</span>
                      </Card>
                    </Col>
                    <Col span={10} push={6}>
                      <Card>
                        <img alt="l2" src={call}></img> <span>QPAY</span>
                      </Card>
                    </Col>
                  </Row>
                  <Row justify="space-around" style={{ marginTop: 20 }}>
                    <Col span={10} push={6}>
                      <Card>
                        <img alt="l3" src={mail}></img> <span>QPAY</span>
                      </Card>
                    </Col>
                    <Col span={10} push={6}>
                      <Card>
                        <img alt="l4" src={map}></img> <span>QPAY</span>
                      </Card>
                    </Col>
                  </Row>
                </Tabs.Item>
              </Tabs>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Payment;
