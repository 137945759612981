/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import { Button, Dropdown, Image, Menu } from "antd";
import logo from "../assets/images/logo/logo2.png";
import { useTranslation } from "react-i18next";
import mn from "../assets/images/icon/mn.png";
import cn from "../assets/images/icon/cn.png";
import lang_logo from "../assets/images/icon/lang_logo.png";

const Header = () => {
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);
  const { t, i18n } = useTranslation();

  const exit = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lang", lng);
  };

  const items = [
    {
      key: "1",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="/" onClick={exit}>
          Гарах
        </a>
      ),
    },
  ];

  const scrollNav = useRef(null);

  // const callRegister = () => {
  //   navigate("/register");
  // };

  return (
    <>
      <header
        className="site-header home-one-header top-0 w-full z-[999] rt-sticky"
        ref={scrollNav}
      >
        <div className="main-header">
          <div className="container">
            <div className="flex items-center justify-between">
              <Link to={"/home"}>
                <div href="#" className="mb-10 block flex relative mt-6">
                  <img className="h-20" src={logo} alt="logo" />
                  <div
                    className=" mt-8"
                    style={{
                      // background: "linear-gradient(to right, #513884, #1fc3dc)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    <span className="gradient-text header-logo-text">
                      TAX TIME TMZ
                    </span>
                  </div>
                </div>
              </Link>
              <div className="flex items-center flex-1">
                <div className="flex-1 main-menu relative mr-[70px]">
                  <ul className="menu-active-classes">
                    <li>
                      <Link to={"/home"}>{t("Нүүр хуудас")}</Link>
                    </li>
                    <li>
                      <Link to={"/about"}>{t("Бидний тухай")}</Link>
                    </li>
                    <li>
                      <Link to={"/feature"}>{t("Онцлог давуу тал")}</Link>
                    </li>
                    <li>
                      <Link to={"/blogs"}>{t("Мэдээ мэдээлэл")}</Link>
                    </li>
                    <li>
                      <Link to={"/jobs"}>{t("Ажлын байр")}</Link>
                    </li>
                    <li>
                      <Link to={"/contacts"}>{t("Холбоо барих")}</Link>
                    </li>
                  </ul>
                </div>
                <div className="flex-none flex space-x-[18px]">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => changeLanguage("mn")}>
                          <img
                            src={mn}
                            style={{ height: "20px", width: "30px" }}
                          ></img>
                        </Menu.Item>
                        <Menu.Item onClick={() => changeLanguage("cn")}>
                          <img
                            src={cn}
                            style={{ height: "20px", width: "30px" }}
                          ></img>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button style={{ border: "none" }}>
                      <img
                        src={lang_logo}
                        style={{ height: "30px", width: "30px" }}
                      ></img>
                    </Button>
                  </Dropdown>
                  <div className="block lg:hidden">
                    <button
                      type="button"
                      className="text-3xl md:w-[56px] h-10 w-10 md:h-[56px] rounded bg-[#F8F8F8] flex flex-col items-center justify-center menu-click"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <iconify-icon
                        icon="cil:hamburger-menu"
                        rotate="180deg"
                      ></iconify-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {activeMobileMenu && (
        <MobileMenu
          activeMenu={activeMobileMenu}
          setActiveMenu={setActiveMobileMenu}
        />
      )}
    </>
  );
};

export default Header;
