/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/logo.png";
import { useTranslation } from "react-i18next";

const MobileMenu = ({ activeMenu, setActiveMenu }) => {
  const [showBlogMenu, setShowBlogMenu] = useState(false);
  const { t } = useTranslation();

  const handleShowBlogMenu = () => {
    setShowBlogMenu(!showBlogMenu);
  };
  return (
    <>
      <div className="openmobile-menu fixed top-0 h-screen pt-10 pb-6 bg-white shadow-box2 w-[320px] overflow-y-auto flex flex-col z-[999] active-mobile-menu">
        <div className="flex justify-between px-6 flex-none">
          <Link to={"/home"} style={{ width: 300, height: 80 }}>
            <img src={logo} alt="logo" />
          </Link>
          <span
            className=" text-3xl text-black cursor-pointer rt-mobile-menu-close"
            onClick={() => {
              setActiveMenu(!activeMenu);
            }}
          >
            <iconify-icon icon="fe:close"></iconify-icon>
          </span>
        </div>
        <div className="mobile-menu mt-6 flex-1 ">
          <ul className="menu-active-classNamees">
            <li>
              <Link to={"/home"}>{t("Нүүр хуудас")}</Link>
            </li>
            <li>
              <Link to={"/about"}>{t("Бидний тухай")}</Link>
            </li>
            <li>
              <Link to={"/feature"}>{t("Онцлог давуу тал")}</Link>
            </li>
            <li>
              <Link to={"/blogs"}>{t("Мэдээ мэдээлэл")}</Link>
            </li>
            <li>
              <Link to={"/jobs"}>{t("Ажлын байр")}</Link>
            </li>
            <li>
              <Link to={"/contacts"}>{t("Холбоо барих")}</Link>
            </li>
          </ul>
        </div>
      </div>{" "}
      <div className={`rt-mobile-menu-overlay ${activeMenu && "active"}`}></div>
    </>
  );
};

export default MobileMenu;
