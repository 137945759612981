import React from "react";
import bg1 from "../assets/images/all-img/9.jpg";
import { useTranslation } from "react-i18next";

const Main = () => {
  const { t, i18n } = useTranslation();
  const lg = localStorage.getItem("lang");
  return (
    <section className="bg-[url('../images/banner/3.png')] bg-cover bg-no-repeat bg-center overflow-hidden">
      <div className="main-container">
        <div className="main-sub">
          <div className="main-sub-2">
{lg==="cn" &&(
            <h1 className="main-h1" style={{color:"black"}}>
              <span >{t("Үнэ цэнэтэй зөвлөгөө")}{" "}</span>
              <span >{t("Үр ашигтай бизнес")}</span>
            </h1>
            )}
         {lg !=="cn" &&(   
            <h1 className="main-h1">
              <span className="shape-bg">{t("Үнэ цэнэтэй")} </span>{" "}
              {t("зөвлөгөө")}{" "}
              <span className="shape-bg"> {t("Үр ашигтай")}</span> {t("бизнес")}
            </h1>
         )}
          </div>
        </div>

        <div className="main-c1">
          <div className="main-c1-1">
            <div className="container relative main-c1-1-header">
              <h1 className="main-c1-1-header-h1">
                {t("Татварын зөвлөх үйлчилгээ")}
              </h1>
              <ul className="main-c1-1-header-ul ">
                <li>• {t("Татварын тайлан мэдээ бэлтгэх үйлчилгээ")}</li>
                <li>
                  •{t("Татварын багц хуулийн талаар зөвлөгөө өгөх үйлчилгээ")}
                </li>
                <li>• {t("Татварын эрсдлийг тодорхойлох үйлчилгээ")}</li>
                <li>
                  •
                  {t(
                    "Татварын хяналт шалгалт, маргаантай асуудалд зөвлөх үйлчилгээ"
                  )}
                </li>
                <li>• {t("Татварын төлөвлөлт, тооцоолол хийх үйлчилгээ")}</li>
                <li>• {t("Татварын хуулийн талаар сургалт явуулах")} </li>
              </ul>
              <h1 className="main-c1-1-header-h1">
                {t("Санхүүгийн зөвлөх үйлчилгээ")}
              </h1>
              <ul className="main-c1-1-header-ul">
                <li>• {t("Санхүүгийн тайлан баланс гаргах")}</li>
                <li>• {t("Санхүүгийн шинжилгээ")} </li>
              </ul>
              <h1 className="main-c1-1-header-h1">
                {t("Бизнесийн зөвлөх үйлчилгээ")}
              </h1>
              <ul className="main-c1-1-header-ul">
                <li>• {t("Гадаадын хөрөнгө оруулагчдад")} </li>
                <li>• {t("Дотоодын хөрөнгө оруулагчдад")}</li>
              </ul>
            </div>
          </div>
          <div className="main-img-container">
            <div className="main-img-container-sub">
              <img src={bg1} alt="manOne" className="main-img" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Main;
