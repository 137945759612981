import React from "react";
import Footer from "../../Footer";
// import BlogPost2 from "../../ad/BlogPost2";
import Header from "../../Header";
import { international } from "../../../constant/images";

const SingleBlog6 = () => {
  return (
    <>
      <Header />
      <div style={{ marginTop: 150 }}></div>
      {/* <PageBanner title={"Нийтлэл"} pageName="Нийтлэл" /> */}
      <div className="container" style={{ padding: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: 1000,
            margin: "0px auto",
          }}
        >
          <div style={{}}>
            <div
              style={{
                fontSize: 40,
                lineHeight: 1,
                fontFamily: "Roboto",
                fontWeight: 700,
                marginTop: 30,
                // textAlign: "justify",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                display: "flex",
                letterSpacing: 2,
              }}
            >
              Олон улсын татварын талаар
            </div>
            <div
              style={{
                marginTop: 40,
                fontSize: 15,
                fontFamily: "Roboto",
                textAlign: "justify",
                color: "#3c414e",
                transition: "font-size .2s ease-in-out ",
              }}
            >
              <p
                style={{
                  marginBottom: 10,
                  textAlign: "justify",
                }}
              >
                {
                  "Даяаршлын эрин үед худалдаа, хөрөнгө оруулалт, эдийн засгийн олон талт үйл ажиллагаа урьд өмнө төсөөлж байгаагүйгээр өргөжин тэлж, нэг улс орны төдийгүй бүс нутгийн хүрээнээс ч хальж тив дамжсан томоохон корпорациуд үүсэн бий болж өргөн цар хүрээгээр ажиллах болжээ."
                }
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Хувь хүн, хуулийн этгээдийн дэлхий даяар олсон орлогод татвар
                ногдуулахад татварын давхардлыг тодорхой хэмжээгээр арилгах,
                олсон орлогод нь аль улс татвар ногдуулах, нэг орлогод хоёр улс
                давхардуулж татвар ногдуулахгүй байх, түүнтэй холбоотой
                маргааныг журмын дагуу шийдвэрлэхийн тулд улс орнуудын татварын
                алба хооронд мэдээлэл солилцох боломжийг бүрдүүлдэг давхар
                татварын гэрээ буюу орлого ба хөрөнгийн татварыг давхардуулан
                ногдуулахгүй байх хэлэлцээрийг ашигладаг.
              </p>
              <div
                style={{
                  display: "flex",
                  margin: "0px auto",
                  justifyContent: "center",
                  padding: 20,
                }}
              >
                <img src={international} alt="sss" />
              </div>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Монгол Улс 2023 оны 11 дүгээр сарын байдлаар 143 улстай Давхар
                татварын гэрээтэй буюу харилцан мэдээлэл солилцох эрх зүйн
                боломжтой болсон байна.
              </p>

              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Манай бизнес эрхлэгчид энэхүү гэрээг өөрийн үйл ажиллагаандаа
                хэрхэн ашиглаж татвараа зөв зохистой төлөх талаарх ойлголт
                төдийлөн хангалтгүй байсаар л байна.
              </p>

              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Давхар татварын гэрээтэй улс оронтой харилцан, ажил гүйлгээ
                хийсэн л бол татвар төлөхгүй гэсэн ойлголттой нэг хэсэг байхад
                гэрээний дагуу татварын хөнгөлөлт эдлэх боломжтой гэдгийг
                ойлгоогүй бизнес эрхлэгчид бас байнаа.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Давхар татварын гэрээг ашиглахдаа “оршин суугч”, “оршин суугч
                бус” гэж хэн болохоо тодорхойлж ойлгохоос эхэлдэг. Тухайлбал,
                улс орнуудад оршин суугчийн тодорхойлолт өөр өөр байдаг боловч
                ерөнхий зарчим нь тухайн хүн, хуулийн этгээдийн үндсэн гэрийн
                хаяг болон тайлангийн жилд тухайн улсад өөрийн биеэр амьдарсан
                өдрийн тоогоор илэрхийлэгддэг. Энэ мэт нарийвчилсан ойлголтууд
                олон байдаг.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Монгол улс 2020 оноос хэрэгжүүлж эхэлсэн татварын шинэчилсэн
                багц хуулиуддаа олон улсын татварын асуудлуудыг нарийн
                томьёолсон байдаг. Тэдгээр олон улсын татвартай холбоотой
                заалтын нэгээхэн хэсэг нь хил дамнасан ажил гүйлгээг харилцан
                хамааралтай талтай хийсэн тохиолдолд ЭЗХАХБ-ийн үнэ шилжилтийн
                гарын авлагад заасан аргачлалын дагуу бодит үнийн зарчимд
                нийцүүлэх хэрэгтэй болох мөн үүний тулд татвар төлөгч өөрийн
                ажил гүйлгээг гуравдагч этгээдтэй хийсэнтэй адил үнэ, нөхцөлийг
                ашигласан гэдгийг нотлох судалгаа, шинжилгээг татварын албанд
                тайлагнах үүрэгтэй болох тухай зүйл заалтууд орсон.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Нэгэнт хуулинд заавал биелүүлэх ёстой гэж заасан тул хуулиа
                биелүүлээгүй бол хариуцлага ногдуулахаар ч байгаа. Энэ
                хариуцлагын хэмжээ нь харьцангуй өндөр байгаа нь тэдгээр
                тайлангуудыг үнэн зөв тайлагнаагүйгээсээ болоод бизнесийн хэвийн
                үйл ажиллагаагаа алдагдуулах өндөр эрсдэлд ч орох магадлалтай
                байна.
              </p>
              <p style={{ marginBottom: 10, textAlign: "justify" }}>
                Иймд олон улсын татвар, давхар татварын гэрээ, үнэ шилжилтийн
                тайлангуудын талаарх мэдлэг мэдээллийг бизнес эрхлэгчдэд олгох,
                бизнес эрхлэгчид өөрсдөө татварын эрх зүйн мэдлэгээ байнга
                дээшлүүлэх хэрэгтэй байгаа нь харагдаж байна.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleBlog6;
